// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-customer-feedback-content-js": () => import("./../../../src/Components/Templates/customerFeedbackContent.js" /* webpackChunkName: "component---src-components-templates-customer-feedback-content-js" */),
  "component---src-components-templates-events-page-js": () => import("./../../../src/Components/Templates/EventsPage.js" /* webpackChunkName: "component---src-components-templates-events-page-js" */),
  "component---src-components-templates-gallery-content-js": () => import("./../../../src/Components/Templates/GalleryContent.js" /* webpackChunkName: "component---src-components-templates-gallery-content-js" */),
  "component---src-components-templates-guest-data-form-js": () => import("./../../../src/Components/Templates/GuestDataForm.js" /* webpackChunkName: "component---src-components-templates-guest-data-form-js" */),
  "component---src-components-templates-outlets-content-js": () => import("./../../../src/Components/Templates/OutletsContent.js" /* webpackChunkName: "component---src-components-templates-outlets-content-js" */),
  "component---src-components-templates-page-content-js": () => import("./../../../src/Components/Templates/PageContent.js" /* webpackChunkName: "component---src-components-templates-page-content-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amethhyyst-xci-menu-js": () => import("./../../../src/pages/amethhyyst-xci/menu.js" /* webpackChunkName: "component---src-pages-amethhyyst-xci-menu-js" */),
  "component---src-pages-ametrine-24-main-menu-js": () => import("./../../../src/pages/ametrine24/main_menu.js" /* webpackChunkName: "component---src-pages-ametrine-24-main-menu-js" */),
  "component---src-pages-ami-token-js": () => import("./../../../src/pages/ami-token.js" /* webpackChunkName: "component---src-pages-ami-token-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-holi-guest-data-js": () => import("./../../../src/pages/holi_guestData.js" /* webpackChunkName: "component---src-pages-holi-guest-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kp-js": () => import("./../../../src/pages/kp.js" /* webpackChunkName: "component---src-pages-kp-js" */),
  "component---src-pages-loader-js": () => import("./../../../src/pages/loader.js" /* webpackChunkName: "component---src-pages-loader-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-outlets-js": () => import("./../../../src/pages/outlets.js" /* webpackChunkName: "component---src-pages-outlets-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-ticket-booking-js": () => import("./../../../src/pages/ticket-booking.js" /* webpackChunkName: "component---src-pages-ticket-booking-js" */),
  "component---src-pages-wallet-exchange-js": () => import("./../../../src/pages/wallet-exchange.js" /* webpackChunkName: "component---src-pages-wallet-exchange-js" */)
}

